import React from "react";
import { useSelector } from "react-redux";
import Constants from "../../../constants/Constants";

const AttachmentsTab = () => {
  const activeCase = useSelector((state) => state.cases.activeCase);
  return (
    <div className="attachments-tab">
      <h5>Attachments</h5>
      <div className="main-documents">
        <h4>Main Attachments</h4>
        {activeCase.attachments && activeCase.attachments.length > 0
          ? activeCase.attachments.map((attachment, index) => (
              <a
                key={index}
                href={`${Constants.STORAGE_BUCKET_URL}/${Constants.typeOfService.CASES}/${attachment.attachmentUrl}`}
                className="file-item shadow-sm"
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                {attachment.attachmentUrl}
              </a>
            ))
          : "No attachments."}
      </div>
      <div className="comments-documents">
        <h4>Comments Attachments</h4>
        {activeCase.comments
          ? activeCase.comments.map((comment) =>
              comment.commentAttachmentsIds
                ? comment.commentAttachmentsIds.map((attachmentUrl, index) => (
                    <a
                      key={index}
                      href={`${Constants.STORAGE_BUCKET_URL}/${Constants.typeOfService.CASES}/${attachmentUrl}`}
                      className="file-item shadow-sm"
                      download
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {attachmentUrl}
                    </a>
                  ))
                : "No attachments."
            )
          : ""}
      </div>
    </div>
  );
};

export default AttachmentsTab;
